<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    // dashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
